<script>
import { usePageStore } from '@voix/store/pageStore'

export default {
  props: {
    fields: { type: Object },
  },
  data() {
    return {
      loaded: true,
      navigationHeight: 150,
      player: null,
      volume: null,
      showPlayButton: false,
      showModal: false,
      videoPlayCount: 0,
    }
  },
  computed: {
    currentPage() {
      const pageStore = usePageStore()
      return pageStore.currentPage
    },
    ratio() {
      return (
        Number.parseInt(this.fields.width.value) / Number.parseInt(this.fields.height.value)
      )
    },
    invertedRatio() {
      return (
        Number.parseInt(this.fields.height.value) / Number.parseInt(this.fields.width.value)
      )
    },
    ratioStyles() {
      return {
        height: `${this.invertedRatio * 100}vw`,
        minWidth: `${this.invertedRatio * 100}vh`,
      }
    },
    videoUrl() {
      if (this.fields.cfId.value) {
        return (
          `https://iframe.videodelivery.net/${
             this.fields.cfId.value
             }?muted=true&autoplay=true&controls=false`
        )
      }
      return null
    },

    gradientStyles() {
      const rgb = this.hexToRgb(this.fields.bottomGradient.value) ?? { r: 255, g: 255, b: 255 }

      return {
        background: `linear-gradient(0deg, rgba(${rgb.r}, ${rgb.g}, ${rgb.b}, 1) 20%, rgba(0, 0, 0, 0) 80%)`,
      }
    },
  },
  watch: {
    breakpoint() {
      this.positionHero()
    },
  },
  mounted() {
    defineSlice({
      name: {
        label: 'Vimeo Hero',
        group: 'global',
      },
      preview: 'SlicesGlobalHeroesVimeoHero.jpg',
      fields: {
        cfId: { type: 'text', label: 'CloudFlare ID' },
        customAspectRatio: { type: 'checkbox', label: 'Custom Ratio?' },
        height: { type: 'number', label: 'Ratio Y', default: '30' },
        width: { type: 'number', label: 'Ratio X', default: '16' },
        mute: { type: 'checkbox', label: 'Muted', default: true },
        image: { type: 'media', label: 'Mobile Image', breakpoints: { default: { width: 600, height: 600 }, lg: { width: 600, height: 600 } } },
        bgImage: { type: 'media', label: 'Video Background Image', instructions: 'Shows after the video is complete.', breakpoints: { default: { width: 1200, height: 800 } } },
        bottomGradient: {
          type: 'color',
          label: 'Bottom Gradient',
          enabled: false,
          default: '#ffffff',
        },
      },
      slots: [],
    })

    this.positionHero()

    if (window && this.fields.cfId.value) {
      const script = document.createElement('script')
      script.type = 'text/javascript'
      script.async = true
      script.onload = () => {
        if (typeof Stream !== 'undefined') {
          this.player = Stream(this.$refs.video)
          this.player.addEventListener('ended', this.onPlayEnded)
        }
      }
      script.src = 'https://embed.videodelivery.net/embed/sdk.latest.js'
      this.$refs.video.appendChild(script)
    }
  },
  methods: {
    onPlaying() {
      console.log('playing')
    },
    onPlayEnded() {
      if (this.fields.bgImage.value && this.fields.bgImage.value.length > 0) {
        this.$refs.bgimage.style.opacity = 1
        this.$refs.videowrapper.style.opacity = 0
      }
    },
    positionHero() {
      setTimeout(() => {
        this.refreshNavigationHeight()
        this.loaded = true
      }, 500)
    },
    refreshNavigationHeight() {
      const navigationEl = document.getElementById('navigation')
      if (navigationEl)
        this.navigationHeight = navigationEl.offsetHeight
    },
    play() {
      this.player.play()
      this.showPlayButton = false
    },
    pause() {
      this.player.pause()
    },
    hexToRgb(hex) {
      // Expand shorthand form (e.g. "03F") to full form (e.g. "0033FF")
      const shorthandRegex = /^#?([a-f\d])([a-f\d])([a-f\d])$/i
      hex = hex.replace(shorthandRegex, (m, r, g, b) => {
        return r + r + g + g + b + b
      })

      const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex)
      return result
        ? {
            r: Number.parseInt(result[1], 16),
            g: Number.parseInt(result[2], 16),
            b: Number.parseInt(result[3], 16),
          }
        : null
    },
  },
}
</script>

<template>
  <div v-if="fields.cfId.value">
    <div v-if="!fields.customAspectRatio.value" class="relative">
      <div class="w-full h-[50vh] md:h-screen relative hero-slide overflow-hidden">
        <div
          class="block absolute inset-0 w-full h-full"
        >
          <VoixMedia
            v-if="fields.bgImage.value && fields.bgImage.value.length"
            v-slot="slotProps"
            :field="fields.bgImage"
            :background="true"
          >
            <div
              ref="bgimage"
              class="absolute inset-0 w-full h-full face bg-no-repeat bg-cover bg-center" style="opacity: 0"
              :style="{
                backgroundImage: `url(${slotProps.image})`,
              }"
            />
          </VoixMedia>
          <div ref="videowrapper" class="absolute inset-0 video-cover animate-fade-in duration-1000 ease-out">
            <iframe
              ref="video"
              allow="autoplay"
              :src="videoUrl"
              class=" object-cover w-full h-full"
              webkitallowfullscreen
              mozallowfullscreen
              allowfullscreen
              frameborder="0"
            />
          </div>
        </div>

        <VoixMedia
          v-if="fields.image.value && fields.image.value.length"
          v-slot="slotProps"
          :field="fields.image"
          :background="true"
        >
          <div
            class="md:hidden w-full h-full bg-cover bg-center"
            style="height: 300px"
            :style="{
              backgroundImage: `url(${slotProps.image})`,
            }"
          />
        </VoixMedia>
      </div>
    </div>
    <div v-else class="relative">
      <div
        class="w-full md:h-screen relative hero-slide overflow-hidden pointer-events-none"
      >
        <div class="hidden md:block video-background">
          <div class="video-foreground">
            <iframe
              ref="video"
              allow="autoplay"
              :style="ratioStyles"
              :src="videoUrl"
              class="w-full"
              webkitallowfullscreen
              mozallowfullscreen
              allowfullscreen
              frameborder="0"
            />
          </div>
        </div>
        <VoixMedia
          v-if="fields.image.value && fields.image.value.length"
          v-slot="slotProps"
          :field="fields.image"
          :background="true"
        >
          <div
            class="md:hidden w-full h-full bg-cover bg-center"
            style="height: 300px"
            :style="{
              backgroundImage: `url(${slotProps.image})`,
            }"
          />
        </VoixMedia>
      </div>
    </div>
    <div
      v-if="fields.bottomGradient.enabled"
      :style="gradientStyles"
      class="absolute bottom-0 left-0 w-full bg-black h-48 z-10"
    />
  </div>
</template>

<style scoped>
.video-cover {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  pointer-events: none;
  overflow: hidden;
}
.video-cover iframe,
.video-cover object,
.video-cover embed,
.video-cover video {
  width: 100vw;
  height: 56.25vw; /* Given a 16:9 aspect ratio, 9/16*100 = 56.25 */
  min-height: 100vh;
  min-width: 177.77vh; /* Given a 16:9 aspect ratio, 16/9*100 = 177.77 */
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.video-foreground {
  video,
  iframe {
    width: 100vw;
    min-height: 100vh;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
}

.video-cover {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  pointer-events: none;
  overflow: hidden;
}
.video-cover iframe,
.video-cover object,
.video-cover embed,
.video-cover video {
  width: 100vw;
  height: 56.25vw; /* Given a 16:9 aspect ratio, 9/16*100 = 56.25 */
  min-height: 100vh;
  min-width: 177.77vh; /* Given a 16:9 aspect ratio, 16/9*100 = 177.77 */
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
</style>
